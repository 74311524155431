<template>
  <v-footer
    id="home-footer"
    color="white"
    dark
    min-height="72"
  >
    <v-container>
      <v-row>
        <v-col
          class="black--text text-center"
          cols="12"
          md="3"
        >
          <base-img
            :src="require(`@/assets/logo_footer.png`)"
            color="gray"
            contain
            height="50"
            width="300"
          />
          Copyright &copy; 2005 NAMKANG
        </v-col>
        <v-col
          class="black--text text-center"
          cols="12"
          md="4"
        >
        (우14447) 경기도 부천시 오정로96번길 7-15(삼정동)<br>
        Tel. 032-683-7711 / Fax. 032-683-7713

        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <div class="d-flex flex-wrap justify-md-start justify-center justify-md-none">
            <template v-for="(s, i) in social">
              <a
                :key="s"
                class="black--text pa-1 pa-md-0"
                href="#"
                v-text="s"
              />

              <v-responsive
                v-if="i < social.length - 1"
                :key="`divider-${s}`"
                class="mx-4 shrink hidden-sm-and-down"
                max-height="24"
              >
                <v-divider vertical />
              </v-responsive>
            </template>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'HomeFooter',

    data: () => ({
      social: [
        '개인정보보호정책',
        '이메일추출방지정책',
      ],
    }),
  }
</script>

<style lang="sass">
  #home-footer a
    text-decoration: none
</style>
